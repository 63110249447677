import React, { Component } from "react";

import StarfieldAnimation from "react-starfield-animation";
import { TypeAnimation } from "react-type-animation";
import { FaEnvelope } from "react-icons/fa";

import background from "./stars.jpg";

export default class App extends Component {
  render() {
    return (
      <div
        style={{
          background: `url(${background})`,
          backgroundSize: "stretch",
          minHeight: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <div style={{ display: "flex" }}>
          <h1
            style={{
              color: "#fff",
              fontSize: "3em",
              fontFamily: 'Quicksand, "Helvetica Neue", sans-serif',
              textShadow: "2px 2px 8px rgba(0, 0, 0, 0.5)",
            }}
          >
            Astromedia Tech
          </h1>
        </div>
        <TypeAnimation
          // Same String at the start will only be typed once, initially
          sequence={[
            "We develop software to streamline business operations.",
            1000,
            "We develop software to enhance customer experience.",
            1000,
            "We develop software to automate Workflows.",
            1000,
            "Interested? Contact us to find out more.",
            5000,
            "",
          ]}
          speed={40} // Custom Speed from 1-99 - Default Speed: 40
          style={{ fontSize: "2em", color: "#fff" }}
          wrapper="span" // Animation will be rendered as a <span>
          repeat={Infinity} // Repeat this Animation Sequence infinitely
        />
        <div style={{ marginTop: "36px", zIndex: 999 }}>
          <a
            href="mailto:salah@astromedia.tech"
            target="_blank"
            rel="noreferrer"
          >
            <FaEnvelope size={"36px"} color="white" />
          </a>
        </div>
        <StarfieldAnimation
          numParticles={400}
          style={{
            position: "absolute",
            zIndex: 1,
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
          }}
        />
      </div>
    );
  }
}
